import { createRouter, createWebHistory } from "vue-router";
import Body from "../components/BodyComponent.vue";
import Auth from "../helpers/auth";
import { gitHubRoute } from "@/router/routes/gitub";

const routes = [
  {
    path: "/auth",
    component: () => import("../auth/auth.vue"),
    children: [
      {
        path: "login",
        name: "Login 1",
        component: () => import("../auth/login.vue"),
        meta: {
          title: "Login sahifasi",
        },
      },

      {
        path: "forgotpassword",
        name: "forgot Password",
        component: () => import("../auth/forgotPassword.vue"),
        meta: {
          title: " Parolni Tiklash",
        },
      },
      {
        path: "register",
        name: "register 1",
        component: () => import("../auth/register.vue"),
        meta: {
          title: " Ro'yxatdan o'tish ",
        },
      },
    ],
  },
  {
    path: "/",
    component: Body,
    redirect: "/dashboard",
    meta: {
      requiresAuth: true,
      title: "Asosiy Sahifa",
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("../views/course/allcourse.vue"),
        meta: {
          requiresAuth: true,
          title: "Kurslar",
        },
      },
      {
        path: "categories",
        name: "Categories",
        component: () => import("../pages/CategoriesPage.vue"),
        meta: {
          title: "Default Dashboard ",
          requiresAuth: true,
        },
      },
      {
        path: "courses/:slug",
        name: "course",
        component: () => import("../views/course/mycourses.vue"),
      },
      {
        name: "Courses",
        path: "courses",
        component: () => import("../views/course/allcourse.vue"),
      },
      {
        name: "AllCourses",
        path: "my-courses",
        component: () => import("../views/course/mycourses.vue"),
      },
      {
        path: "/course/:slug",
        name: "Kurs",
        meta: {
          meta: {
            title: "Kurs ",
            requiresAuth: true,
          },
        },
        component: () => import("../views/course/CourseDetail.vue"),
      },
      {
        path: "/course/:slug/lecture",
        name: "Lesson",
        component: () => import("../views/lesson/LessonPage.vue"),
        meta: {
          title: "Darslar ",
          requiresAuth: true,
        },
        children: [
          {
            path: "/course/:slug/lecture/",
            name: "Single Test",
            component: () => import("../views/notfound/NotFound.vue"),
          },
          {
            path: "/course/:slug/lesson/:title/",
            name: "Single Lesson",
            component: () => import("../views/lesson/SingleLesson.vue"),
          },
        ],
      },
      {
        path: "/cards",
        name: "Cards",
        meta: {
          title: "Mening hisobim",
          requiresAuth: true,
        },
        component: () => import("../views/card/CardComponent.vue"),
      },

      {
        path: "/settings",
        name: "Settings",
        component: () => import("../views/settings/settingspage.vue"),
      },
      {
        path: "/settings/account",
        name: "Settings  Account",
        component: () => import("../views/settings/SettingsComponent.vue"),
      },
      {
        path: "/settings/privacy",
        name: "Settings  Privacy",
        component: () => import("../views/settings/PrivacyPolice.vue"),
      },
      {
        path: "/plans",
        name: "Settings  Plans",
        component: () => import("../views/settings/Plans.vue"),
      },
      {
        path: "/examples",
        name: "Examples",
        component: () => import("../views/examples/Example.vue"),
      },
      // {
      //     path: '/tab',
      //     name: 'Examples tab',
      //     component: () => import('../views/examples/tabbed.vue')
      // },
      {
        path: "/example/id",
        name: "Example",
        component: () => import("../views/examples/SelectComponent.vue"),
      },

      {
        path: "solution-description",
        name: "Example Solution Description",
        component: () => import("../views/examples/solution-description.vue"),
      },

      {
        path: "/groups",
        name: "Groups",
        component: () => import("../views/groups/Groups.vue"),
      },
      // {
      //   path: "/profile",
      //   name: "Profile",
      //   component: () => import("../views/Profile/ProfilePage.vue"),
      // },
      {
        path: "update-profile",
        name: "Update Profile",
        component: () => import("../views/Profile/UpdateProfile.vue"),
      },
      {
        path: "update-experience",
        name: "Update Experience",
        component: () => import("../views/Profile/UpdateExpereince.vue"),
      },
      {
        path: "update-award",
        name: "Update Award",
        component: () => import("../views/Profile/updateAward.vue"),
      },
      {
        path: "update-language",
        name: "Update Language",
        component: () => import("../views/Profile/UpdateLanguage.vue"),
      },
      {
        path: "update-skill",
        name: "Update Skill",
        component: () => import("../views/Profile/updateSkill.vue"),
      },
      {
        path: "/balance",
        name: "Balance History",
        component: () => import("../views/balanceHistory/BalanceHistory.vue"),
      },
      {
        path: "/chat",
        name: "Chat",
        component: () => import("../views/chat/ChatComponent.vue"),
        children: [
          {
            path: "/chat/",
            name: "Empty Test",
            component: () => import("../views/chat/EmptyChat.vue"),
          },
          {
            path: "/chat/:id",
            name: "Single Test",
            component: () => import("../views/chat/SingleChat.vue"),
            props: true,
          },
        ],
      },
      gitHubRoute,
      {
        path: "/:pathMatch(.*)*",
        name: "Not Found",
        component: () => import("../views/notfound/NotFound.vue"),
      },
    ],
  },
  {
    path: "/e-book/:token",
    name: "Download eBook",
    component: () => import("../views/eBook/EBookDownload.vue"),

    meta: {
      requiresAuth: false,
      title: "Kitobni yuklab olish",
    },
  },
  {
    path: "/checklist/math/:usertoken",
    name: "Download Check-list",
    component: () => import("../views/checkList/downloadChecklist.vue"),

    meta: {
      requiresAuth: false,
      title: "Checklistni yuklab olish",
    },
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  },
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title;
  const path = ["/auth/login", "/auth/register", "/auth/forgotpassword"];
  if (path.includes(to.path)) {
    if (Auth.isAuthenticatedUser()) {
      return next("/dashboard");
    }
  }
  if (to.meta.requiresAuth) {
    if (!Auth.isAuthenticatedUser()) {
      return next("/auth/login");
    }
  }
  return next();
});

export default router;
