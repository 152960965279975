<template>
  <router-view />
</template>

<script>
import Swal from "sweetalert2";

export default {
  computed: {
    text() {
      return this.message.text;
    },

    message() {
      return this.$store.getters.getMessageData;
    },
  },
  watch: {
    text(newText, oldText) {
      if (
        newText !== oldText &&
        newText !== undefined &&
        this.text !== undefined
      ) {
        let status = this.message.status;

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: status,
          title: newText,
        });
      }
      this.$store.commit("setMessage", {});
    },
  },
};
</script>
<style lang="scss">
// Import Main styles for this application
</style>
