import Auth from "./auth";
class Token {
  isValid(token) {
    let payload = this.payload(token);

    let email = JSON.parse(Auth.decryptData(localStorage.getItem('dml0YWxfY2FjaGU='), 'programmer.uz.local.samarkand')).email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return (
      !payload.error &&
      (payload.iss === process.env.VUE_APP_URL + "/login" ||
        payload.iss === process.env.VUE_APP_URL + "/register" ||
        payload.iss === process.env.VUE_APP_URL + "/activate-user" ||
      payload.iss === process.env.VUE_APP_URL + `/reset-password-verify`)
    ) && 'dml0YWxfY2FjaGU=' in localStorage && emailPattern.test(email);
  }

  payload(token) {
    let payload = token.split(".")[1];
    return this.decode(payload);
  }

  decode(payload) {
    try {
      let ascii = atob(payload);
      if (ascii !== "" || ascii != null) return JSON.parse(atob(payload));
      else {
        Auth.logOut();
        return {
          error: true,
        };
      }
    } catch (e) {
      Auth.logOut();
      return {
        error: true,
      };
    }
  }
}

export default new Token();
