import axios from "axios";
import Swal from "sweetalert2";
import store from "@/store/index";
import router from "@/router";
import { StateStatus } from "@/helpers/state_status";
import Auth from "./auth";

class ApiRequest {
  token = null;
  endPoint = process.env.VUE_APP_URL + "/";
  state = StateStatus.normal;

  isLoading() {
    return store.getters.getState === StateStatus.loading;
  }

  isSuccess() {
    return store.getters.getState === StateStatus.success;
  }
  filterUrl(url) {
    if (url[0] === "/") {
      url = url.substring(1);
    }
    return url;
  }
  getToken() {
    return store.getters.getUserToken;
  }
  getHeader() {
    let token = this.token ?? this.getToken();
    return {
      Authorization: "Bearer " + token,
      "Access-Control-Allow-Origin": "*",
      "X-Authorization":
        "Programmer eyJpZCI6MSwibmFtZSI6Ikphdm9oaXIiLCJjcmVhdGVkX2F0IjoiMjAyMy0wNy0yMlQxMDozMTozMy4wMDAwMDBaIn0=",
    };
  }

  get(url) {
    let headers = this.getHeader();
    url = this.endPoint + this.filterUrl(url);

    // console.log("%crequest:" + url, "background-color: blue");
    return new Promise(function (resolve, reject) {
      axios
        .get(url, { headers: headers })
        .then((res) => {
          resolve(res);

          //  console.log(
          //   "url:" + url + "\n",
          //   "%cResponse:" + JSON.stringify(res.data),
          //   "background-color: green"
          // );
        })
        .catch((e) => {
          let text = e.response.data.errors
            ? e.response.data.errors[0]
            : "Something error.";
          sweet(text, e.response.status, e.response.data.message);
          // console.log("%cError:" + e.response.data, "background-color: red");
          reject(e);
        });
    });
  }

  post(url, data = {}) {
    let headers = this.getHeader();
    url = this.endPoint + this.filterUrl(url);
    // console.log("request post:", url);

    return new Promise(function (resolve, reject) {
      axios
        .post(url, data.form, { headers })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          // i`m used  toaseted notification replace swet alert for  register
          reject(error);
          // console.log(error);
          store.dispatch("setAlert", {
            text: error.response.data.errors[0],
            status: "error",
          });
        });
    });
  }

  putData(url, data = {}) {
    let headers = this.getHeader();
    url = this.endPoint + this.filterUrl(url);
    return new Promise(function (resolve, reject) {
      axios
        .put(url, data.form, { headers: headers })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
          sweet(
            e.response.data.errors[0],
            e.response.status,
            e.response.data.message
          );
          // console.log("putData", e.response.data);
        });
    });
  }

  put(url, data = {}) {
    let headers = this.getHeader();
    url = this.endPoint + this.filterUrl(url);
    let formData = data.form;
    formData.append("_method", "PUT");
    return new Promise(function (resolve, reject) {
      axios
        .post(url, formData, { headers: headers })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
          // console.log("put ", e.response.data);
          sweet(
            e.response.data.errors[0],
            e.response.status,
            e.response.data.message
          );
        });
    });
  }

  delete(url) {
    let headers = this.getHeader();
    url = this.endPoint + this.filterUrl(url);
    return new Promise(function (resolve, reject) {
      axios
        .delete(url, { headers: headers })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          reject(e);
          let text = e.response.data.errors
            ? e.response.data.errors[0]
            : e.response.message;
          sweet(text, e.response.status, e.response.data.message);
          // console.log("delete", e.response.data);
        });
    });
  }
}

function sweet(text, status, message) {
  if (status === 401) {
    Auth.logOut("STATUS  401 ");
    return;
  }
  console.log(message);
  // axios.get(
  //   `https://api.telegram.org/bot6150606671:AAHWFzbnI_5GiIiu5feKqlRMzwRn6S-GZ-I/sendmessage?chat_id=1291007595&text=${message}`
  // );
  if (status === 403) {
    store.dispatch("hasAccessAction", false);

    router.push("/");
  }
  Swal.fire({
    title: "Something went wrong:",
    text: text + " " + status,
    icon: "error",
    status: 200,
  });
}

export default new ApiRequest();
