import { createStore } from "vuex";
import layout from "./modules/layout";
import menu from "./modules/menu";
import getResponse from "@/store/modules/getResponse";
import auth from "@/store/modules/auth";
import chat from "@/store/modules/chat";
import Menu from "../data/menu.json";

// import process_card from "@/store/modules/process_card";
export default createStore({
  state: {
    rightToggle: true,
    token: localStorage.getItem("_auth_token") || null,
    message: {},
    sidebarUnfoldable: false,
    formData: JSON.parse(localStorage.getItem("userinfo")),
    data: Menu.data,
    has_access: true,
    prevLesson: null,
    nextLesson: null,
  },
  getters: {
    getUserToken(state) {
      return state.token;
    },
    getMessageData(state) {
      return state.message;
    },
    getLessonsToggle(state) {
      return state.lessonsToggle;
    },
    getRightToggle: (state) => state.rightToggle,
    getFormData(state) {
      return state.formData;
    },
    getHasAccess(state) {
      return state.has_access;
    },
    getPrevLesson(state) {
      // console.log(state.prevLesson, "bu prev state");
      return state.prevLesson;
    },
    getNextLesson(state) {
      // console.log(state.nextLesson, "bu next state");
      return state.nextLesson;
    },
  },
  mutations: {
    toggleSidebar(state) {
      // console.log(state.data);
      state.sidebarVisible = !state.sidebarVisible;
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value;
    },
    setLessonsToggle(state, payload) {
      state.lessonsToggle = payload;
    },
    setToken(state, payload) {
      state.token = payload;
      localStorage.setItem("_auth_token", payload);
    },
    setMessage(state, payload) {
      state.message.text = payload.text;
      state.message.status = payload.status;
    },
    setRightToggle: (state) => {
      state.rightToggle = !state.rightToggle;
    },
    setFormData(state, payload) {
      state.formData = payload;
      localStorage.setItem("userinfo", JSON.stringify(payload));
    },
    setItem: (state, payload) => {
      state.item = payload;
    },
    hasAccess: (state, payload) => {
      // console.log(payload, "BU HAS ACCESS QIYMATI  FALSE");

      state.has_access = payload;
      // console.log(payload, "BU HAS ACCESS QIYMATI  TRUE");
    },

    setPrevlesson: (state, payload) => {
      // console.log(state.prevLesson + "bu  default" , payload + " bu yangi")
      state.prevLesson = payload;
    },
    setNextlesson: (state, payload) => {
      state.nextLesson = payload;
    },
  },
  actions: {
    setAlert(context, payload) {
      // console.log(context.state.has_access, "bu storeda turgan ");
      // console.log(payload, "bu   paydan keladigan true false lik");
      context.commit("setMessage", payload);
    },
    hasAccessAction(context, payload) {
      context.commit("hasAccess", payload);
    },
  },
  modules: {
    layout,
    menu,
    getResponse,
    auth,
    chat
  },
});
