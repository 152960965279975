const state = {
  searchData: [],
  chat: false,
};

// getters
const getters = {
  getOpenChat: (state) => state.chat,
};

// mutations
const mutations = {
  setOpenchat: (state) => {
    state.chat = !state.chat;
  },
};

// actions
const actions = {
  // openchat: (context, payload) => {
  //     context.commit("menu/setOpenchat", payload);
  // },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
