export const gitHubRoute =
    {
        path: "/github",
        component: () => import("@/views/github/GithubComponent.vue"),
        meta: {
            title: "GitHub",
            requiresAuth: true,
        },

    };

export default {
    gitHubRoute,
};
