<template>
  <div>
    <div class="container-fluid">
      <div class="page-title">
        <div class="row">
          <div class="col-6 d-flex">
            <h4>{{ title }}</h4>
          </div>
          <div class="col-6">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ path: '/' }">
                  <vue-feather type="home" />
                </router-link>
              </li>
              <li
                v-if="main"
                class="breadcrumb-item"
              >
                {{ main }}
              </li>
              <li
                v-if="title"
                class="breadcrumb-item active"
              >
                {{ title }}
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadCrumb",
  components: {},
  props: {
    title: {
      default: "",
    },
    main: {
      default: "",
    },
  },
};
</script>
