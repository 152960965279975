import ApiRequest from "@/helpers/request";

const state = {
  items: [],
  loading: true,
  detail_loading:true,
  item: {},
  detail: {},
  meta: {},
  data: [],
  columns: {},
  course_by_category: [],
  single_lesson: {},
  lessons: [],
};
const getters = {
  getItems(state) {
    return state.items;
  },
  getCourseByCategory(state) {
    return state.course_by_category;
  },
  getDetail(state) {
    // console.log(state.detail , " bu statedagi detail")
    return state.detail;
  },
  getLoading(state) {
    return state.loading;
  },
  getDetailLoading(state) {
    return state.detail_loading;
  },
  getLesson(state) {
    return state.lessons;
  },
  getBalance(state) {
    return state.data;
  },
  getColumns(state) {
    return state.columns;
  },
  getProfile(state) {
    return state.items;
  },
  getSingleLesson(state) {
    return state.single_lesson;
  },
};
// mutations
const mutations = {
  setItems: (state, payload) => {
    state.items = payload;
  },
  setProfile: (state, payload) => {
    state.items = payload;
  },
  setCourse: (state, payload) => {
    state.course_by_category = payload;
  },
  setDetail: (state, payload) => {
    state.detail = payload;
  },
  setLoad: (state, payload) => {
    state.loading = payload;
  },
  setDetailLoad: (state, payload) => {
    state.detail_loading = payload;
  },
  setLesson: (state, payload) => {
    state.lessons = payload;
  },
  setSingleLesson: (state, payload) => {
    state.single_lesson = payload;
  },
  setBalance: (state, payload) => {
    state.data = payload;
  },
  setColumns: (state, payload) => {
    state.columns = payload;
  },
  deleteItem: (state, payload) => {
    for (let i = 0; i < state.items.length; i++) {
      if (state.items[i].values.id === payload) {
        state.items.splice(i, 1);
        break;
      }
    }
  },
};

// actions
const actions = {
  getData: (context, url) => {
    ApiRequest.get(url).then((res) => {
      context.commit("setItems", res.data.data);
      context.commit("setLoad", false);
    });
  },
  getCourse: (context, url) => {
    context.commit("setLoad", true);
    context.commit("setCourse", []);
    ApiRequest.get(url).then((res) => {
      context.commit("setCourse", res.data.data);
      context.commit("setLoad", false);
    });
  },
  getDetail: (context, url) => {
    context.commit("setDetail" , [])
    context.commit("setDetailLoad", true);
    ApiRequest.get(url).then((res) => {
      // console.log(res , "bu detail")

      context.commit("setDetail", res.data.data);
      context.commit("setDetailLoad", false);
    });
  },
  getLesson: (context, url) => {
    context.commit("setLesson", []);
    // context.commit("setLoad", true);
    ApiRequest.get(url).then((res) => {
      context.commit("setLesson", res.data.data);
      // context.commit("setLoad", false);
    });
  },

  getSingleLesson: (context, url) => {
    context.commit("setSingleLesson", []);
    context.commit("setLoad", true);
    ApiRequest.get(url).then((res) => {
      context.commit("setSingleLesson", res.data.data);
      context.commit("setLoad", false);
    });
  },

  getBalance: (context, url) => {
    context.commit("setLoad", true);
    ApiRequest.get(url).then((res) => {
      let obj = Object.keys(res.data.data[0]);
      context.commit("setColumns", obj);
      context.commit("setBalance", res.data.data);
      context.commit("setLoad", false);
    });
  },
  setLoad: (context, payload) => {
    context.commit("setLoad", payload);
  },
  getProfile: (context, url) => {
    ApiRequest.get(url).then((res) => {
      context.commit("setProfile", res.data.data);
      context.commit("setLoad", false);
    });
  },

  createData: (context, url) => {
    ApiRequest.post(url.url, { form: url.form }).then((data) => {
      context.commit(
        "setMessage",
        {
          text: data.data.message,
          status: "success",
        },
        { root: true }
      );
    });
  },
  updateData: (context, url) => {
    ApiRequest.put(url.url, { form: url.form }).then((data) => {
      context.commit(
        "setMessage",
        {
          text: data.data.message,
          status: "success",
        },
        { root: true }
      );
    });
  },
  deleteItem: (context, url) => {
    ApiRequest.delete(url.url + "/" + url.id).then((data) => {
      context.commit(
        "setMessage",
        {
          text: data.data.message,
          status: "success",
        },
        { root: true }
      );
      // console.log(url, "store");

    });
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
