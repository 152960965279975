<template>
  <div>
    <div class="logo-wrapper">
      <router-link to="/"> Programmer UZ </router-link>
      <div class="back-btn" @click="toggle_sidebar">
        <i class="fa fa-angle-left" />
      </div>
      <div class="toggle-sidebar" @click="toggle_sidebar">
        <vue-feather
          id="sidebar-toggle"
          class="status_toggle middle"
          type="grid"
        />
      </div>
    </div>
    <div class="logo-icon-wrapper">
      <router-link to="/">
        <img
          class="img-fluid w-75"
          src="../assets/images/logo.png"
          alt=""
        />
      </router-link>
    </div>
    <nav class="sidebar-main">
      <li
        class="left-arrow"
        :class="{
          'd-none':
            layout.settings.layout_type == 'rtl'
              ? hideLeftArrowRTL
              : hideLeftArrow,
        }"
        @click="
          layoutobject.split(' ').includes('horizontal-wrapper') &&
          layout.settings.layout_type === 'rtl'
            ? scrollToLeftRTL()
            : scrollToLeft()
        "
      >
        <vue-feather type="arrow-left" />
      </li>
      <div id="sidebar-menu">
        <ul
          id="myDIV"
          class="sidebar-links custom-scrollbar"
          :style="[
            layoutobject.split(' ').includes('horizontal-wrapper')
              ? layout.settings.layout_type == 'rtl'
                ? { 'margin-right': margin + 'px' }
                : { 'margin-left': margin + 'px' }
              : { margin: '0px' },
          ]"
        >
          <li class="back-btn">
            <router-link to="/">
              <img
                class="img-fluid"
                src="../assets/images/logo/logo-icon.png"
                alt=""
              />
            </router-link>
            <div class="mobile-back text-right">
              <span>Back</span
              ><i class="fa fa-angle-right pl-2" aria-hidden="true" />
            </div>
          </li>
          <li
            v-for="(menuItem, index) in menuItems"
            :key="index"
            :class="{
              active: menuItem.active,
              'sidebar-main-title': menuItem.type == 'headtitle',
            }"
            class="sidebar-list"
          >
            <!-- link title -->
            <div v-if="menuItem.type == 'headtitle'">
              <h6 class="lan-1">
                {{ menuItem.headTitle1 }}
              </h6>
              <p class="lan-2">
                {{ menuItem.headTitle2 }}
              </p>
            </div>
            <!-- Sub -->
            <label
              v-if="menuItem.badgeType"
              :class="'badge badge-' + menuItem.badgeType"
              >{{ menuItem.badgeValue }}</label
            >
            <a
              v-if="menuItem.type == 'sub'"
              href="javascript:void(0)"
              class="sidebar-link sidebar-title"
              @click="setNavActive(menuItem, index)"
            >
              <vue-feather :type="menuItem.icon" class="top" />
              <span>
                {{ menuItem.title }}
              </span>
              <div v-if="menuItem.children" class="according-menu">
                <i class="fa fa-angle-right pull-right" />
              </div>
            </a>
            <!-- Link -->
            <router-link
              v-if="menuItem.type == 'link'"
              :to="menuItem.path"
              class="sidebar-link sidebar-title"
              router-link-exact-active
            >
              <vue-feather :type="menuItem.icon" class="top" />
              <span>
                {{ menuItem.title }}
              </span>
              <i
                v-if="menuItem.children"
                class="fa fa-angle-right pull-right"
              />
            </router-link>
            <!-- External Link -->
            <a
              v-if="menuItem.type == 'extLink'"
              :href="menuItem.path"
              class="sidebar-link sidebar-title"
              @click="setNavActive(menuItem, index)"
            >
              <vue-feather :type="menuItem.icon" class="top" />
              <span>
                {{ menuItem.title }}
              </span>
              <i
                v-if="menuItem.children"
                class="fa fa-angle-right pull-right"
              />
            </a>
            <!-- External Tab Link -->
            <a
              v-if="menuItem.type == 'extTabLink'"
              :href="menuItem.path"
              target="_blank"
              class="sidebar-link sidebar-title"
              @click="setNavActive(menuItem, index)"
            >
              <vue-feather :type="menuItem.icon" class="top" />
              <span>
                {{ menuItem.title }}
              </span>
              <i
                v-if="menuItem.children"
                class="fa fa-angle-right pull-right"
              />
            </a>
            <!-- 2nd Level Menu -->
            <ul
              v-if="menuItem.children"
              class="sidebar-submenu"
              :class="{ 'menu-open': menuItem.active }"
            >
              <li
                v-for="(childrenItem, index) in menuItem.children"
                :key="index"
                :class="{ active: childrenItem.active }"
              >
                <!-- Sub -->
                <a
                  v-if="childrenItem.type == 'sub'"
                  class="submenu-title"
                  href="javascript:void(0)"
                  @click="setNavActive(childrenItem, index)"
                >
                  {{ childrenItem.title }}
                  <label
                    v-if="childrenItem.badgeType"
                    :class="
                      'badge badge-' + childrenItem.badgeType + ' pull-right'
                    "
                    >{{ childrenItem.badgeValue }}</label
                  >
                  <i
                    v-if="childrenItem.children"
                    class="fa fa-angle-right pull-right mt-1"
                  />
                </a>
                <!-- Link -->
                <router-link
                  v-if="childrenItem.type == 'link'"
                  class="submenu-title"
                  :to="childrenItem.path"
                  router-link-exact-active
                >
                  {{ childrenItem.title }}
                  <label
                    v-if="childrenItem.badgeType"
                    :class="
                      'badge badge-' + childrenItem.badgeType + ' pull-right'
                    "
                    >{{ childrenItem.badgeValue }}</label
                  >
                  <i
                    v-if="childrenItem.children"
                    class="fa fa-angle-right pull-right mt-1"
                  />
                </router-link>
                <!-- External Link -->
                <a
                  v-if="childrenItem.type == 'extLink'"
                  :href="childrenItem.path"
                  class="submenu-title"
                >
                  {{ childrenItem.title }}
                  <label
                    v-if="childrenItem.badgeType"
                    :class="
                      'badge badge-' + childrenItem.badgeType + ' pull-right'
                    "
                    >{{ childrenItem.badgeValue }}</label
                  >
                  <i
                    v-if="childrenItem.children"
                    class="fa fa-angle-right pull-right mt-1"
                  />
                </a>
                <!-- External Tab Link -->
                <a
                  v-if="childrenItem.type == 'extTabLink'"
                  class="submenu-title"
                  :href="childrenItem.path"
                  target="_blank"
                >
                  {{ childrenItem.title }}
                  <label
                    v-if="childrenItem.badgeType"
                    :class="
                      'badge badge-' + childrenItem.badgeType + ' pull-right'
                    "
                    >{{ childrenItem.badgeValue }}</label
                  >
                  <i
                    v-if="childrenItem.children"
                    class="fa fa-angle-right pull-right mt-1"
                  />
                </a>
                <!-- 3rd Level Menu -->
                <ul
                  v-if="childrenItem.children"
                  class="nav-sub-childmenu submenu-content"
                  :class="{ opensubchild: childrenItem.active }"
                >
                  <li
                    v-for="(childrenSubItem, index) in childrenItem.children"
                    :key="index"
                  >
                    <!-- Link -->
                    <router-link
                      v-if="childrenSubItem.type == 'link'"
                      :to="childrenSubItem.path"
                      router-link-exact-active
                    >
                      {{ childrenSubItem.title }}
                      <label
                        v-if="childrenSubItem.badgeType"
                        :class="
                          'badge badge-' +
                          childrenSubItem.badgeType +
                          ' pull-right'
                        "
                        >{{ childrenSubItem.badgeValue }}</label
                      >
                      <i
                        v-if="childrenSubItem.children"
                        class="fa fa-angle-right pull-right"
                      />
                    </router-link>
                    <!-- External Link -->
                    <router-link
                      v-if="childrenSubItem.type == 'extLink'"
                      :to="childrenSubItem.path"
                      router-link-exact-active
                    >
                      {{ childrenSubItem.title }}
                      <label
                        v-if="childrenSubItem.badgeType"
                        :class="
                          'badge badge-' +
                          childrenSubItem.badgeType +
                          ' pull-right'
                        "
                        >{{ childrenSubItem.badgeValue }}</label
                      >
                      <i
                        v-if="childrenSubItem.children"
                        class="fa fa-angle-right pull-right"
                      />
                    </router-link>
                    <!-- External Tab Link -->
                    <router-link
                      v-if="childrenSubItem.type == 'extLink'"
                      :to="childrenSubItem.path"
                      router-link-exact-active
                    >
                      {{ childrenSubItem.title }}
                      <label
                        v-if="childrenSubItem.badgeType"
                        :class="
                          'badge badge-' +
                          childrenSubItem.badgeType +
                          ' pull-right'
                        "
                        >{{ childrenSubItem.badgeValue }}</label
                      >
                      <i
                        v-if="childrenSubItem.children"
                        class="fa fa-angle-right pull-right"
                      />
                    </router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <li
        class="right-arrow"
        :class="{
          'd-none':
            layout.settings.layout_type == 'rtl'
              ? hideRightArrowRTL
              : hideRightArrow,
        }"
        @click="
          layoutobject.split(' ').includes('horizontal-wrapper') &&
          layout.settings.layout_type == 'rtl'
            ? scrollToRightRTL()
            : scrollToRight()
        "
      >
        <vue-feather type="arrow-right" />
      </li>
    </nav>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { layoutClasses } from "@/constants/layout";
export default {
  name: "SidebarComponent",
  data() {
    return {
      width: 0,
      height: 0,
      margin: 0,
      hideRightArrowRTL: true,
      hideLeftArrowRTL: true,
      hideRightArrow: true,
      hideLeftArrow: true,
      menuWidth: 0,
      toggle_sidebar_var: false,
      clicked: false,
      layoutobj: {},
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      sidebar: (state) => state.layout.sidebarType,
    }),
    layoutobject: {
      get: function () {
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.width < 991) {
      this.layout.settings.layout = "Dubai";
      this.margin = 0;
    }
    setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      this.menuWidth = elmnt.offsetWidth;
      this.menuWidth > window.innerWidth
        ? ((this.hideRightArrow = false), (this.hideLeftArrowRTL = false))
        : ((this.hideRightArrow = false), (this.hideLeftArrowRTL = true));
    }, 500);
    this.layoutobject = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[
      this.layout.settings.layout
    ];
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.menuItems.filter((items) => {
      if (items.path === this.$route.path)
        this.$store.dispatch("menu/setActiveRoute", items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subSubItems);
        });
      });
    });
    const mycourse =  JSON.parse(localStorage.getItem("mycourse"))
    // console.log(typeof mycourse)
    if(mycourse === true){
    this.$store.commit("menu/addMyCourses", true);
    }
  },
  methods: {
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setNavActive", item);
    },
    handleResize() {
      this.width = window.innerWidth - 310;
    },
    scrollToRightRTL() {
      this.temp = this.width + this.margin;
      // Checking condition for remaing margin
      if (this.temp === 0) {
        this.margin = this.temp;
        this.hideRightArrowRTL = true;
      }
      // else
      else {
        this.margin += this.width;
        this.hideRightArrowRTL = false;
        this.hideLeftArrowRTL = false;
      }
    },
    scrollToLeftRTL() {
      // If Margin is reach between screen resolution
      // console.log("this.margin", this.margin);
      if (this.margin <= -this.width) {
        this.margin += -this.width;
        this.hideLeftArrowRTL = true;
      }
      //Else
      else {
        this.margin += -this.width;
        this.hideRightArrowRTL = false;
      }
    },
    scrollToLeft() {
      // console.log("left");

      // If Margin is reach between screen resolution
      if (this.margin >= -this.width) {
        this.margin = 0;
        this.hideLeftArrow = true;
      }
      //Else
      else {
        this.margin += this.width;
        this.hideRightArrow = false;
      }
    },
    scrollToRight() {
      this.temp = this.menuWidth + this.margin;
      // Checking condition for remaing margin
      if (this.temp < this.menuWidth) {
        this.margin = -this.temp;
        this.hideRightArrow = true;
      }
      // else
      else {
        this.margin += -this.width;
        this.hideLeftArrow = false;
      }
    },
  },
};
</script>
