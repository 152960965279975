<template>
  <div class="header-wrapper row m-0">
    <form
      class="form-inline search-full"
      action="#"
      method="get"
      :class="{ open: searchOpen }"
    >
      <div class="form-group w-100">
        <div class="Typeahead Typeahead--twitterUsers">
          <div class="u-posRelative">
            <input
              v-model="terms"
              class="demo-input Typeahead-input form-control-plaintext w-100"
              type="text"
              placeholder="Search  .."
              name="q"
              title=""
              autofocus
              @keyup="searchterm"
            />
            <div class="spinner-border Typeahead-spinner" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <vue-feather
              class="close-search"
              type="x"
              @click="search_close()"
            />
          </div>
          <div
            v-if="menuItems.length"
            :class="searchResult ? 'Typeahead-menu is-open' : 'Typeahead-menu'"
          >
            <div
              v-for="(menuItem, index) in menuItems.slice(0, 8)"
              :key="index"
              class="ProfileCard u-cf"
            >
              <div class="ProfileCard-avatar header-search">
                <vue-feather :type="menuItem.icon" />
              </div>
              <div class="ProfileCard-details">
                <div class="ProfileCard-realName">
                  <span @click="removeFix()"
                    ><router-link
                      :to="{ path: menuItem.path }"
                      class="realname"
                      >{{ menuItem.title }}</router-link
                    ></span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            :class="
              searchResultEmpty ? 'Typeahead-menu is-open' : 'Typeahead-menu'
            "
          >
            <div class="tt-dataset tt-dataset-0">
              <div class="EmptyMessage">
                Your search turned up 0 results. Opps There are no result found.
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="left-header col horizontal-wrapper pl-0">
      <ul class="horizontal-menu">
        <li>
          <div class="header-logo-wrapper">
            <div class="logo-wrapper">
              <router-link to="/">
                <img
                  class="img-fluid"
                  src="../assets/images/logo/logo.png"
                  alt
                />
              </router-link>
            </div>
            <div class="toggle-sidebar d-flex">
              <vue-feather
                @click="toggle_sidebar"
                id="sidebar-toggle"
                class="status_toggle middle sidebar-toggle"
                type="align-center"
              />
              <p class="ml-4 p-0 mt-0 mb-0">Kechagidanda yaxshiroq bo'ling!</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="nav-right col-3 pull-right right-header p-0">
      <ul class="nav-menus d-flex align-items-center">
        <!--        <li>-->
        <!--          <span class="header-search"><vue-feather-->
        <!--            type="search"-->
        <!--            @click="search_open()"-->
        <!--          /></span>-->
        <!--        </li>-->
        <li>
          <div class="mode">
            <i
              v-show="mixLayout === 'light-only'"
              class="fa fa-moon-o"
              @click="customizeMixLayout('dark-only')"
            />
            <i
              v-show="mixLayout === 'dark-only'"
              class="fa fa-lightbulb-o"
              @click="customizeMixLayout('light-only')"
            />
          </div>
        </li>

        <li class="maximize">
          <a
            class="text-dark"
            href="javascript:void(0)"
            @click="toggle_fullscreen()"
          >
            <vue-feather type="maximize"
          /></a>
        </li>

        <li class="onhover-dropdown p-0 mr-0">
          <div class="d-flex align-items-center">
            <vue-feather
              class="b-r-10 mr-2 p-2"
              type="user"
              style="transform: translateY(-3px)"
            />
            <div class="media-body">
              <span>{{ fullName }}</span>
            </div>
          </div>
          <ul class="profile-dropdown onhover-show-div">
            <li>
              <router-link to="/balance">
                <vue-feather type="file-text" /><span> To'lovlar tarixi</span>
              </router-link>
            </li>
            <li @click="logOut">
              <a><vue-feather type="log-in" /><span>Tizimdan chiqish</span></a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
var body = document.getElementsByTagName("body")[0];
import { mapState } from "vuex";

import Auth from "../helpers/auth";
export default {
  name: "HeaderPage",

  data() {
    return {
      terms: "",
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      close_sidebar_var: false,
      clicked: false,
      mobile_toggle: false,
      mobile_search: false,
      openbonusUI: false,
      openLevelmenu: false,
      openlanguage: false,
      mobile_accordian: false,
      mixLayout: localStorage.getItem("mode") || "light-only",
      fullName: "",
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.searchData,
      megamenuItems: (state) => state.menu.megamenu,
    }),
  },
  watch: {
    "$i18n.locale"(to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path);
      }
    },
    menuItems: function () {
      this.terms ? this.addFix() : this.removeFix();
      if (!this.menuItems.length) this.searchResultEmpty = true;
      else this.searchResultEmpty = false;
    },
  },
  created() {
    this.fullName = JSON.parse(
      Auth.decryptData(
        localStorage.getItem("dml0YWxfY2FjaGU="),
        "programmer.uz.local.samarkand"
      )
    ).displayName;
  },
  methods: {
    logOut() {
      Auth.logOut("HEADER LOGOUT");
    },
    toggle_sidebar() {
      this.$store.dispatch("menu/opensidebar");
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setBonusNavActive", item);
    },
    openlangpicker() {
      this.openlanguage = !this.openlanguage;
    },
    openlevelmenu() {
      this.openLevelmenu = !this.openLevelmenu;
    },
    openmegamenu() {
      this.openbonusUI = !this.openbonusUI;
    },
    closeBonusUI() {
      this.openbonusUI = false;
    },
    search_open() {
      this.searchOpen = true;
    },
    search_close() {
      this.searchOpen = false;
    },
    searchterm: function () {
      this.$store.dispatch("menu/searchTerm", this.terms);
    },
    changeLocale(locale) {
      this.setLang(locale);
    },
    mobileaccordian() {
      this.mobile_accordian = !this.mobile_accordian;
    },
    addFix() {
      // body.classList.add("offcanvas");
      this.searchResult = true;
    },
    removeFix() {
      body.classList.remove("offcanvas");
      this.searchResult = false;
      this.terms = "";
    },
    toggle_fullscreen() {
      if (
        (document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    customizeMixLayout(val) {
      this.mixLayout = val;
      this.$store.dispatch("layout/setLayout", val);
    },
  },
};
</script>
<style scoped>
.profile-dropdown {
  width: 330px !important;
}
@media only screen and (max-width: 575.98px) {
  .onhover-dropdown,
  .nav-menus {
    margin: 0 !important;
  }
  .onhover-dropdown:hover .onhover-show-div {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
  .media-body {
    display: none;
  }
}
</style>
